

















































import { computed, ComputedRef, defineComponent, provide, reactive, ref } from '@vue/composition-api'
import {
  ApolloClients, useMutation,
  UseMutationReturn,
  useQuery,
  UseQueryReturn,
  useResult,
  UseResultReturn
} from '@vue/apollo-composable'
import { apolloClient } from '@/api/graphql/apollo'
import {
  Mutation, MutationDelete__Sales_SaleArgs,
  MutationDelete__Sales_SoldItemsArgs,
  MutationUpdate__Sales_SoldItemsArgs,
  Sales_Sale, Update__Sales_SoldItems__Input
} from '@/models/generated/graphql/ErpBackend'
import {
  DELETE_SALE,
  DELETE_SOLD_ITEM, GET_SALES,
  GET_SALES_ORDER_FOR_DELETION, GQL_INPUT__SALE_ORDERS,
  UPDATE_SOLD_ITEMS
} from '@/api/graphql/Constants/Orders'
import tAlert from '@/components/notifications/tAlert.vue'
import store from '@/store/store'
import { DELETE_SHIPMENT, DELETE_SHIPMENT_ORDER } from '@/api/graphql/Constants/Shipments'
import { SALES_ITEM_STATUS } from '@/models/ExtraBackendModels'

export default defineComponent({
  name: 'DeleteSalesOrder',
  components: {
    't-alert': tAlert
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    provide(ApolloClients, {
      default: apolloClient
    })

    const alert = reactive({
      message: '',
      type: 'info'
    })
    const success = computed(() => alert.type === 'success')
    const isDeleting = ref(false)

    const { result, loading, onError }:
      UseQueryReturn<Sales_Sale, { id: string }> = useQuery(
        GET_SALES_ORDER_FOR_DELETION,
        () => ({
          id: props.id
        }),
        () => ({
          enabled: !!props.id
        })
      )
    onError(() => {
      alert.message = 'We had a problem retrieving details for this order.'
      alert.type = 'error'
    })
    const order: UseResultReturn<Sales_Sale | null> = useResult(result, null)
    const hasSoldItems: ComputedRef<boolean> = computed(() => (order.value?.st_items?.length ?? 0) > 0)
    const hasShipments: ComputedRef<boolean> = computed(() => {
      if (order.value?.shipment_order ?? false) {
        if ((order.value?.shipment_order?.shipments?.length ?? 0) > 0) {
          return true
        }
      }
      return false
    })
    const hasShipmentOrder: ComputedRef<boolean> = computed(() => order.value?.shipment_order !== null)
    const orderIsEmpty: ComputedRef<boolean> = computed(() => !hasSoldItems.value && !hasShipmentOrder.value)

    // delete shipments mutation
    const {
      mutate: DeleteShipment
    }: UseMutationReturn<Mutation, { id: string }> =
      useMutation(
        DELETE_SHIPMENT
      )

    // delete shipment order mutation
    const { mutate: DeleteShipmentOrder }:
      UseMutationReturn<Mutation, { id: string }> =
      useMutation(
        DELETE_SHIPMENT_ORDER
      )

    // un allocate mutation
    const { mutate: UnAllocateSoldItems }:
      UseMutationReturn<Mutation, MutationUpdate__Sales_SoldItemsArgs> =
      useMutation(
        UPDATE_SOLD_ITEMS
      )

    // delete sold items mutation
    const { mutate: DeleteSoldItems }:
      UseMutationReturn<Mutation, MutationDelete__Sales_SoldItemsArgs> =
      useMutation(
        DELETE_SOLD_ITEM
      )

    // delete sale mutation
    const { mutate: DeleteSale }:
      UseMutationReturn<Mutation, MutationDelete__Sales_SaleArgs> =
      useMutation(
        DELETE_SALE,
        () => ({
          update: (cache, { data }) => {
            try {
              const store: any = cache.readQuery({ query: GET_SALES, variables: GQL_INPUT__SALE_ORDERS })
              const index = store.sales.findIndex((s: Sales_Sale) => s.id === props.id)
              if (index) {
                store.sales.splice(1, index)
              }
              cache.writeQuery({ query: GET_SALES, variables: GQL_INPUT__SALE_ORDERS, data: store })
            } catch {
              store.dispatch('data/changeRefresh', { bool: true })
            }
          }
        })
      )

    async function Delete () {
      const hasAllocatedItems = order.value?.st_items?.some(i => i.item?.id)
      const hasShipments = (order.value?.shipment_order?.shipments?.length ?? 0) > 0
      isDeleting.value = true
      try {
        // if order has items to un allocate first
        if (hasAllocatedItems) {
          // prepare un allocate input
          const soldItemsToUnAllocate: Update__Sales_SoldItems__Input[] = []
          for (const item of order?.value?.st_items ?? []) {
            soldItemsToUnAllocate.push({
              id: item.id,
              item_id: '',
              status_id: SALES_ITEM_STATUS.TO_ALLOCATE
            })
          }
          // un allocate
          await UnAllocateSoldItems({ input: soldItemsToUnAllocate })
        }
        // delete sold items
        for (const item of order?.value?.st_items ?? []) {
          await DeleteSoldItems({ id: item.id })
        }
        // delete shipments
        if (hasShipments) {
          for (const shipment of order?.value?.shipment_order?.shipments ?? []) {
            await DeleteShipment({ id: shipment.id })
          }
        }
        // delete sale
        await DeleteSale({ id: order?.value!.id })
        // delete shipment order
        if (order?.value?.shipment_order) {
          await DeleteShipmentOrder({ id: order?.value?.shipment_order?.id })
        }
        alert.message = 'Successfully deleted sale'
        alert.type = 'success'
      } catch (error) {
        alert.message = error
        alert.type = 'error'
      } finally {
        isDeleting.value = false
      }
    }

    function Cancel () {
      store.dispatch('grid/resetDialog')
    }

    return {
      order,
      hasSoldItems,
      hasShipments,
      hasShipmentOrder,
      orderIsEmpty,
      loading,
      isDeleting,
      success,
      alert,
      emit,
      Delete,
      Cancel
    }
  }
})
