import gql from 'graphql-tag'
import errorHandling from '../errorHandling'

export default {
  mixins: [errorHandling],
  methods: {
    /**
     * Apollo mixin helper that grabs the number of items from a an order
     * @param {Number} id the id of the purchase order
     * @param {String} order 'purchase' or 'sale'
     * @return {Object} order
     */
    async getMoniesAndCountOfOrder (id, order) {
      const pQuery = gql`
        query GetPurchaseMoneyAndLineNumber ($id: ID!) {
          order: purchases_purchase (input: { id: $id, annotations: [{ name: "lineNumber", aggr_field: "pt_items__line_number", aggr_type: MAX }] }) {
            id
            annotations {
              name
              value
            }
          }
        }
      `
      const sQuery = gql`
        query GetSaleMoneyAndLineNumber ($id: ID!) {
          order: sales_sale (input: { id: $id, annotations: [{ name: "lineNumber", aggr_field: "st_items__line_number", aggr_type: MAX }] }) {
            id
            annotations {
              name
              value
            }
          }
        }
      `
      try {
        const response = await this.$apollo.query({
          query: order === 'purchase' ? pQuery : sQuery,
          variables: { id: id },
          fetchPolicy: 'no-cache'
        })
        return response.data.order
      } catch (error) {
        throw new Error(this.errorHandler(error))
      }
    }
  }
}
