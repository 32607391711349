<template>
  <div>
    <tab-bar-wrapper @newRow="createST"/>
    <div id="grid-container" class="grid-extended">
      <grid-wrapper
        :contextMenuItems="contextMenuItems"
        :componentName="$options.name"
        :annotations="annotations"
        :query="query"
        :filter="filter"
        :columns="columns"
        :nested-grid="true"
        :gridMode="gridMode"
        @ready="ready"
        @setComments="setComments"
        @setMasterObject="setCurrentOrderObject"
        @cell-key-down="gridKbEventSender"
      >
      </grid-wrapper>
    </div>
    <action-bar-wrapper
      :commentList="commentList"
      @saveComments="saveComments"
    >
    </action-bar-wrapper>
    <v-dialog
      lazy
      :width="width"
      v-model="showDialog"
      persistent
    >
      <add-parts-dialog
        v-if="dialog.component === 'addParts'"
        :orderNumber="selectedOrderId"
        :client="currentOrderClient"
        :rep-id="currentOrderRep"
      />
      <pdf-viewer
        :data="pdfData"
        :custom="pdfRules"
        v-if="dialog.component === 'pdfViewer'"
        @close="$store.dispatch('grid/resetDialog')"
      />
      <delete-sales-order
        v-if="dialog.component === 'delete'"
        :id="selectedOrderObjectId"/>
      <edit-client-po
        v-if="dialog.component === 'edit-client-po'"
        :po="currentOrderClientPO"
        :client-name="currentOrderClient"
        :order-id="selectedOrderObjectId"
        @close="$store.dispatch('grid/resetDialog')"
      />
    </v-dialog>
  </div>
</template>

<script>
import TabBarWrapper from '@/components/wrappers/tabBarWrapper.vue'
import ActionBarWrapper from '@/components/wrappers/actionBarWrapper.vue'
import GridWrapper from '@/components/wrappers/gridWrapper.vue'
import addParts from '../../components/dialogs/addParts'
import grid from '../../components/mixins/grid'
import columns from '../../components/mixins/columns'
import mutateOrders from '../../api/graphql/mutationsJS/mutateOrders'
import moneyFormatter from '../../components/mixins/moneyFormatter'
import { GET_SALE_FOR_PDF, GET_SALES, SALES_DETAILS_FOR_COPY, UPDATE_SALE } from '@/api/graphql/Constants/Orders'
import { COLUMNS__SALES } from '@/lib/agGridColumnConfiguration'
import { CONTEXT_MENU__SALES_ORDERS } from '@/lib/agGridContextMenuConfigurations'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import { KB_SHORTCUT__SALES_ORDERS } from '@/lib/agGridKbShortCuts'
import DeleteSalesOrder from '@/components/dialogs/DeleteSalesOrder'
import ViewPDF from '@/components/dialogs/ViewPDF'
import { prepPdfData } from '@/lib/PdfPrep'
import EditClientPO from '@/components/dialogs/EditClientPO'
import { OrderEvents } from '@/store/orderEvents.ts'
export default {
  name: 'salesOrders',
  mixins: [grid, columns, mutateOrders, moneyFormatter],
  components: {
    'tab-bar-wrapper': TabBarWrapper,
    'action-bar-wrapper': ActionBarWrapper,
    'grid-wrapper': GridWrapper,
    'add-parts-dialog': addParts,
    'pdf-viewer': ViewPDF,
    'delete-sales-order': DeleteSalesOrder,
    'edit-client-po': EditClientPO
  },
  computed: {
    showDialog () {
      return this.dialog.app === 'sts'
    },

    selectedOrderObjectId () {
      return `${this.currentOrderObject?.id ?? '0'}`
    },

    currentOrderRep () {
      return `${this.currentOrderObject?.rep?.id ?? ''}`
    },

    currentOrderClientPO () {
      return this.currentOrderObject?.client_order_number ?? ''
    },

    currentOrderClient () {
      return this.currentOrderObject?.client?.name ?? ''
    }
  },
  data () {
    return {
      width: '80%',
      orderId: null,
      contextMenuItems: (params) => CONTEXT_MENU__SALES_ORDERS(params, this.contextMenuCallback),

      commentList: {
        'internal_comment': {
          name: 'internal_comment',
          label: 'Internal',
          text: '',
          editable: true,
          show: true
        },
        'contract_comment': {
          name: 'contract_comment',
          label: 'Contract',
          text: '',
          editable: true,
          show: true
        },
        'id': false
      },

      // query: (columns) => GRID__GET_SALES(columns),
      query: GET_SALES,
      filter: [],
      annotations: [
        {
          name: 'unAllocated',
          aggr_type: 'COUNT',
          aggr_field: 'st_items',
          aggr_filters: [{ key: 'st_items__item__isnull', value: true }]
        },
        {
          name: 'itemCount',
          aggr_type: 'SUM',
          aggr_field: 'st_items',
          aggr_filters: [{ key: 'st_items__id__gt', value: 0 }]
        }
      ],

      columns: [],
      nestedColumns: [],
      // dialog variables
      selectedOrderId: null,
      selectedOrderShipmentID: null,
      pdfData: {},
      pdfRules: [],
      salesOrder: {}
    }
  },
  methods: {
    /**
     * Sets the order object from the master grid
     * @param obj the object of the master row item from detail grid
     */
    setCurrentOrderObject (obj) {
      this.currentOrderObject = obj
    },

    contextMenuCallback (params) {
      if (params.dataKey) this[params.dataKey] = params.data
      if (params.functionToRun) this[params.functionToRun](params.params)
    },

    gridKbEventSender (params) {
      GridKbEventHandler(params, KB_SHORTCUT__SALES_ORDERS(params, this.contextMenuCallback), [])
    },

    saveAllocations (allocatedParts) {
      for (let i = 0; i < allocatedParts.length; i++) {
        const allocatedPart = allocatedParts[i]
        this.allocatedParts[i].item = allocatedPart.item
      }
      if (allocatedParts.length < this.allocatedParts.length) {
        this.allocatedParts = this.allocatedParts.filter(item => {
          return item.item !== null
        })
      }
    },

    cancelAllocations () {
      this.$store.dispatch('grid/resetDialog')
      this.allocatedParts = []
    },

    ready (params) {
      this.gapi = params.api
      this.capi = params.columnApi
      this.gridOptions = params

      const sort = [{ colId: 'st', sort: 'desc' }]
      this.gapi.setSortModel(sort)
    },
    nestedReady (params) {
      this.nestedGapi = params.api
      this.nestedCapi = params.columnApi
      this.nestedGridOptions = params

      const sort = [{ colId: 'line_number', sort: 'asc' }]
      this.nestedGapi.setSortModel(sort)
    },
    copyTracking (number) {
      const input = document.createElement('input')
      input.setAttribute('value', number)
      document.body.appendChild(input)
      input.select()
      const result = document.execCommand('copy')
      document.body.removeChild(input)
      result && this.$store.dispatch('notifications/createSnackbar', {
        message: `Tracking number: ${number} successfully copied to clipboard.`,
        color: 'success',
        top: true,
        canClose: false,
        timeout: 3000
      })
      return result
    },

    assignST (params) {
      // not doing?
    },

    unAssign (params) {
      // not doing?
    },

    book ({ id }) {
      // TODO:
      this.$apollo.mutate({
        mutation: UPDATE_SALE,
        variables: {
          input: {
            id: id,
            status_id: 86
          }
        }
      })
    },

    copyST (id) {
      this.$store.dispatch('grid/changeSheet', { app: 'topbar', component: 'newSale' })
      setTimeout(() => {
        OrderEvents.$emit('duplicate-sale', { value: id }) // emits to newSale.vue
      }, 500)
    },

    createEarlyInvoice (params) {
      // TODO:
    },

    createST () {
      this.$store.dispatch('sale/start')
      // this.gapi.forEachNode(function (node) {
      //   node.setExpanded(false)
      // })
    },

    /**
     * Sets up adding parts to an order, essentially sets up ID and tells vuex to display dialog
     * @param {Object} data the data for the order
     */
    addParts (data) {
      this.width = this.$vuetify.breakpoint.mdAndDown ? '95%' : '80%'
      this.$store.dispatch('sale/update', { key: 'rep', value: data.rep.id }) // I do this so i can search by that rep
      this.$store.dispatch('sale/update', { key: 'client', value: { id: this.currentOrderObject.client.id } })
      this.selectedOrderId = data.id
      this.$store.dispatch('orders/changeOrderType', { type: 'sale' })
      this.$store.dispatch('grid/changeDialog', { app: 'sts', component: 'addParts' })
    },

    changeShipping () {
      this.$store.dispatch('grid/changeDialog', { app: 'sts', component: 'changeShipping' })
    },

    // View Buttons
    async viewContract (id) {
      this.$store.dispatch('notifications/createSnackbar', {
        message: 'Grabbing data...',
        color: 'info'
      })
      try {
        const response = await this.$apollo.query({
          query: GET_SALE_FOR_PDF,
          variables: { id: id }
        })
        if (response.data.sale) {
          const prepped = prepPdfData.Sale(response.data.sale)
          this.pdfData = prepped.pdf
          this.pdfRules = prepped.rules
          this.width = '90%'
          await this.$store.dispatch('notifications/hideSnackbar')
          await this.$store.dispatch('grid/changeDialog', { app: 'sts', component: 'pdfViewer' })
        }
      } catch (error) {
        let message = ''
        message = error.message ? error.message : error
        this.$store.dispatch('notifications/createSnackbar', {
          message: message,
          color: 'error'
        })
      }
    },

    async viewPickList (id) {
      this.$store.dispatch('notifications/createSnackbar', {
        message: 'Grabbing data...',
        color: 'info'
      })
      try {
        const response = await this.$apollo.query({
          query: GET_SALE_FOR_PDF,
          variables: { id: id }
        })
        if (response.data.sale) {
          const prepped = prepPdfData.PickList(response.data.sale)
          this.pdfData = prepped.pdf
          this.pdfRules = prepped.rules
          this.width = '90%'
          await this.$store.dispatch('notifications/hideSnackbar')
          await this.$store.dispatch('grid/changeDialog', { app: 'sts', component: 'pdfViewer' })
        }
      } catch (error) {
        let message = ''
        message = error.message ? error.message : error
        this.$store.dispatch('notifications/createSnackbar', {
          message: message,
          color: 'error'
        })
      }
    },

    async viewPackSlip (id) {
      this.$store.dispatch('notifications/createSnackbar', {
        message: 'Grabbing data...',
        color: 'info'
      })
      try {
        const response = await this.$apollo.query({
          query: GET_SALE_FOR_PDF,
          variables: { id: id }
        })
        if (response.data?.sale) {
          const prepped = prepPdfData.PackSlipForSale(response.data.sale)
          this.pdfData = prepped.pdf
          this.pdfRules = prepped.rules
          this.width = '90%'
          await this.$store.dispatch('notifications/hideSnackbar')
          await this.$store.dispatch('grid/changeDialog', { app: 'sts', component: 'pdfViewer' })
        }
      } catch (error) {
        let message = ''
        message = error.message ? error.message : error
        this.$store.dispatch('notifications/createSnackbar', {
          message: message,
          color: 'error'
        })
      }
    }
  },
  beforeMount () {
    this.columns = COLUMNS__SALES
  }
}
</script>
