import { render, staticRenderFns } from "./DeleteSalesOrder.vue?vue&type=template&id=b8790262&scoped=true&"
import script from "./DeleteSalesOrder.vue?vue&type=script&lang=ts&"
export * from "./DeleteSalesOrder.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8790262",
  null
  
)

export default component.exports