






























import { computed, defineComponent, provide, ref } from '@vue/composition-api'
import { apolloClient } from '@/api/graphql/apollo'
import { ApolloClients, useMutation } from '@vue/apollo-composable'
import { UPDATE_SALE } from '@/api/graphql/Constants/Orders'
import { Mutation, MutationUpdate__Sales_SaleArgs } from '@/models/generated/graphql/ErpBackend'
import store from '@/store/store'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default defineComponent({
  name: 'EditClientPO',
  components: {
    CancelButton,
    SubmitButton
  },
  props: {
    po: {
      type: String,
      required: true
    },
    orderId: {
      type: [Number, String],
      required: true
    },
    clientName: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    provide(ApolloClients, {
      default: apolloClient
    })
    const clientPo = ref(props.po)
    const success = ref(false)
    const canSubmit = computed(() => props.po !== clientPo.value)

    const {
      mutate,
      loading,
      onDone,
      onError
    } = useMutation<Mutation, MutationUpdate__Sales_SaleArgs>(
      UPDATE_SALE,
      () => ({
        variables: {
          input: {
            id: `${props.orderId}`,
            client_order_number: clientPo.value
          }
        }
      })
    )

    onDone(() => {
      store.dispatch('notifications/createSnackbar', {
        message: 'Update Client PO Successfully.',
        color: 'success'
      })
      success.value = true
    })
    onError((error) => {
      store.dispatch('notifications/createSnackbar', {
        message: error.message,
        color: 'error'
      })
    })

    return {
      emit,
      mutate,
      loading,
      success,
      canSubmit,
      clientPo
    }
  }
})
