<template>
  <v-card>
    <v-card-title style="background-color: #BBDEFB; color: hsl(0, 0%, 30%)">
      <h3>Add Parts Form</h3>
      <v-spacer></v-spacer>
      <h3>{{ this.orderTypeString }}: #{{ this.orderNumber }}</h3>
    </v-card-title>
    <v-card-text>
      <v-container px-0>
        <add-parts-set
          :parts="parts"
          :order="orderType"
          :rep-id="repId"
        />
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-layout row justify-start align-center>
        <t-alert :message="message" :type="type" />
        <v-spacer/>
        <cancel-button :success="succeeded" @click="$store.dispatch('grid/resetDialog')"/>
        <submit-button :disabled="parts.length === 0 || succeeded" :loading="saving" @click="save"/>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import moneyFormatter from '@/components/mixins/moneyFormatter'
import queryOrders from '@/api/graphql/queriesJS/queryOrders'
import mutateOrders from '@/api/graphql/mutationsJS/mutateOrders'
import mutateOrderItems from '@/api/graphql/mutationsJS/mutateOrderItems'
import addParts from '../templates/addPartsTemplate'
import {
  GetAllocatedObjectWithIoSplit,
  GetDjangoMoneyCompatibleInput
} from '@/lib/moneyHelpers'
import {
  CREATE_PURCHASED_ITEMS,
  CREATE_SOLD_ITEMS,
  GET_PURCHASED_ITEMS,
  GET_SOLD_ITEMS, getUpdateSaleMoneyData, UPDATE_SALE_MONEY
} from '@/api/graphql/Constants/Orders'
import { ADD_SERIAL_NUMBER } from '@/api/graphql/Constants/Inventory'
import tAlert from '@/components/notifications/tAlert'
import { apolloClient as apollo } from '@/api/graphql/apollo'
import { CapIt, GetNumberValue } from '@/lib/helpers'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import { GridEvents } from '@/store/gridEvents.js'

export default {
  name: 'addParts',
  mixins: [moneyFormatter, queryOrders, mutateOrders, mutateOrderItems],
  components: {
    'add-parts-set': addParts,
    't-alert': tAlert,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  props: {
    orderNumber: {
      type: Number,
      required: true
    },
    client: {
      type: String,
      required: true
    },
    repId: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    orderType () {
      return this.$store.state.orders.orderType
    },

    orderTypeString () {
      return CapIt(this.orderType ?? '') ?? ''
    },

    isPt () {
      return this.orderType === 'purchase'
    }
  },
  data () {
    return {
      saving: false,
      message: '',
      type: 'info',
      succeeded: false,
      allocateParts: false,
      searched: false,
      parts: [],
      lineNumber: 0,
      startingTotalSales: 0,
      startingCostSales: 0,
      startingSubTotalSales: 0,
      startingSubTotalPurchases: 0,
      startingPartTotalPurchases: 0,
      freightCost: 0
    }
  },
  methods: {
    allocate (part) {
      this.partToAllocate = part
      this.allocateParts = true
    },

    resultHandler ({ message, type }) {
      if (type === 'success') {
        this.succeeded = true
        GridEvents.$emit('refresh-order-details-items', { value: this.orderNumber })
      }
      this.message = message
      this.type = type
    },

    savePtParts () {
      this.saving = true
      let lineNumber = this.lineNumber + 1
      const partsToCreate = []
      for (const part of this.parts) {
        partsToCreate.push({
          current_cost: GetDjangoMoneyCompatibleInput(part.cost),
          line_number: lineNumber,
          original_cost: GetDjangoMoneyCompatibleInput(part.cost),
          part_id: part.id,
          transaction_id: this.orderNumber
        })
        lineNumber++
      }
      this.$apollo.mutate({
        mutation: CREATE_PURCHASED_ITEMS,
        variables: { items: partsToCreate }
      }).then(({ data: { items } }) => {
        try {
          if (this.$route.fullPath.includes('product')) {
            const data = this.$apollo.getClient().readQuery({
              query: GET_PURCHASED_ITEMS,
              variables: { id: this.orderNumber }
            })
            data.items.push(...items)
            this.$apollo.getClient().writeQuery({
              query: GET_PURCHASED_ITEMS,
              variables: { id: this.orderNumber },
              data: data
            })
          }
          this.resultHandler({ message: 'Added parts successfully.', type: 'success' })
        } catch (error) {
          this.resultHandler({ message: 'Created successfully, but could not update local cache. Perform manual refresh of item rows', type: 'warning' })
          this.succeeded = true
        }
      }).catch(() => {
        this.resultHandler({ message: 'Could not create purchased items, this has been logged.', type: 'error' })
      }).finally(() => {
        this.saving = false
      })
    },

    async saveStParts () {
      this.saving = true
      let lineNumber = this.lineNumber + 1
      const partsToCreate = []
      const serialsToAdd = []
      for (const part of this.parts) {
        let allocatedObject = {}
        if (part.item) {
          allocatedObject = GetAllocatedObjectWithIoSplit({ soldItem: part })
          /** @temporary **/
          if (part.sn) {
            serialsToAdd.push({
              id: part.item,
              serial_number: part.sn
            })
          }
        }
        partsToCreate.push({
          ...(part.item && allocatedObject),
          line_number: lineNumber,
          part_id: (part.altPart ? part.altPart.id : part.id),
          swap_type: (part.altPart ? 'ALT' : 'NONE'),
          sold_for: GetDjangoMoneyCompatibleInput(part.soldFor),
          status_id: part.item ? 85 : 18,
          transaction_id: this.orderNumber
        })
        lineNumber++
      }

      this.$apollo.mutate({
        mutation: CREATE_SOLD_ITEMS,
        variables: { items: partsToCreate }
      }).then(async ({ data: { items } }) => {
        try {
          if (this.$route.fullPath.includes(`${this.orderNumber}/product/`)) {
            const data = this.$apollo.getClient().readQuery({
              query: GET_SOLD_ITEMS,
              variables: { id: this.orderNumber }
            })
            data.sales_sold_items.push(...items)
            this.$apollo.getClient().writeQuery({
              query: GET_SOLD_ITEMS,
              variables: { id: this.orderNumber },
              data: data
            })
          }
          const saleUpdateObject = await getUpdateSaleMoneyData(this.orderNumber)
          if (saleUpdateObject?.id) {
            const mutation = {
              mutation: UPDATE_SALE_MONEY,
              variables: { input: saleUpdateObject }
            }
            await apollo.mutate(mutation)
          }
          this.resultHandler({ message: 'Added parts successfully.', type: 'success' })
        } catch {
          this.resultHandler({ message: 'Created successfully, but could not update local cache. Perform manual refresh of item rows', type: 'warning' })
          this.succeeded = true
        }
        if (serialsToAdd.length > 0) this.addSerials(serialsToAdd)
      }).catch(() => {
        this.resultHandler({ message: 'Could not create sold items, this has been logged.', type: 'error' })
      }).finally(() => {
        this.saving = false
      })
    },

    addSerials (serials) {
      const promises = []
      for (const serial of serials) {
        const promise = this.$apollo.mutate({
          mutation: ADD_SERIAL_NUMBER,
          variables: { id: serial.id, serial: serial.serial_number }
        })
        promises.push(promise)
      }
      Promise.all(promises).catch(() => {
        this.resultHandler({ message: 'Created sold items ok but could not add serials. Andrew has been notified :)', type: 'warning' })
      })
    },

    save () {
      this.isPt ? this.savePtParts() : this.saveStParts()
    }
  },
  async beforeMount () {
    const type = this.isPt ? 'purchase' : 'sale'
    const order = await this.getMoniesAndCountOfOrder(this.orderNumber, type)
    const lineNumberMax = order.annotations?.find(a => a.name === 'lineNumber')?.value ?? 'None'
    this.lineNumber = GetNumberValue(lineNumberMax)
  },
  mounted () {
    document.getElementById('part-field').focus()
  },
  beforeDestroy () {
    this.$store.dispatch('data/resetAutofillLists')
  }
}
</script>
